import { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import dateFormat from 'dateformat';
import Linkify from 'react-linkify';

const MessageCardTitle = styled.div`
	color: ${({ seen }) => (seen === "true" ? '#a39d9d' : '#ffffff')};
	font-size: ${({ seen }) => (seen === "true" ? '0.7em' : '0.8em')};
	font-weight: ${({ seen }) => (seen === "true" ? 'normal' : 'bold')};
	height: 1em;
	display: flex;
	align-items: center;
`
const MessageCardDateTitle = styled.div`
	color: #c6c2c2;
	font-size: ${({ seen }) => (seen === "true" ? '0.5em' : '0.6em')};
	font-weight: ${({ seen }) => (seen === "true" ? 'normal' : 'bold')};
	height: 1em;
	display: flex;
	align-items: center;
`
const MessageCardContent = styled.div`
	white-space: pre-wrap; // render multi-lines text
	color: ${({ seen }) => (seen === "true" ? '#a39d9d' : '#ffffff')};
	font-size: ${({ seen }) => (seen === "true" ? '0.8em' : '0.9em')};
	font-weight: ${({ seen }) => (seen === "true" ? 'normal' : 'bold')};
`
const MessageCardWrapper = styled.div`
	display: flex;
`
const MessageCardUserWrapper = styled.div`
	display: flex;
	flex-direction: row; // column or row
	justify-content: space-around;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	padding-left: 1em;
`
const MessageCardUserTitleWrapper = styled.div`
	display: flex;
	flex-direction: column; // column or row
	justify-content: space-around;
	margin-left: 0.5em;
	margin-right: 8px;
	
`
const MessageCardContentWrapper = styled.div`
`
const MessageCardImage = styled.img`
	height: 30px;
	width: 30px;
	border-radius: 50%;
`
const FlexRowLink = styled(Link)`
	display: flex;
	flex-direction: row;
`
class MessageCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { content, created, user_id, user_title, user_image, seen } = this.props

		return (
			<MessageCardWrapper>
				<MessageCardUserWrapper>
					<FlexRowLink key={user_id} to={`/user/${user_id}`}>
						<MessageCardImage src={user_image} />
						<MessageCardUserTitleWrapper>
							<MessageCardTitle seen={seen} >{user_title}</MessageCardTitle>
							<MessageCardDateTitle seen={seen} >{dateFormat(new Date(created), "d mmm HH:MM")}</MessageCardDateTitle>
						</MessageCardUserTitleWrapper>
					</FlexRowLink>
				</MessageCardUserWrapper>
				<MessageCardContentWrapper>
					<MessageCardContent seen={seen} ><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{content}</div></Linkify></MessageCardContent>
				</MessageCardContentWrapper>
			</MessageCardWrapper>
		)
	}
}

export default MessageCard
