import styled from 'styled-components'
import { Component } from 'react'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
	padding: 2em 0.3em;
	margin-left: 0.5em;
	color: #ffffff;
	text-decoration: none;
	font-size: 1em;
	text-align: center;
`

const NavContainer = styled.nav`
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

/*
function go_to_my_profile() { window.location.href = "/user/my_profile";}
// <StyledLink $isFullLink onClick={() => go_to_my_profile()}>Mon&nbsp;profil</StyledLink>		bug css sur la page de login !
*/

function go_to_my_profile() {
	window.location.href = "/user/my_profile";
}

class Header extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { notif_count } = this.props
		return (
			<NavContainer>
				<div>
					<span style={{ paddingLeft: '3em' }}></span>
					<StyledLink $theme='dark' to="/">
						Sorties
					</StyledLink>
					<StyledLink $theme='dark' to="/users">
						Utilisateurs
					</StyledLink>
					<StyledLink $theme='dark' to="/podium">
						Podium
					</StyledLink>
					<StyledLink $theme='dark' to="/chat">
						Discuter
					</StyledLink>
					<StyledLink $theme='dark' to="/notifications">
						{notif_count !== 0 && (<mark>&nbsp;{notif_count} Notifs&nbsp;</mark>)}
						{notif_count === 0 && ("Notifs")}
					</StyledLink>
				</div>
			</NavContainer>
		)
	}
}

export default Header
