import $ from 'jquery';
import * as Constants from '../utils/Constants';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import ActivitySmallCard from '../components/ActivitySmallCard'
import { StyledLink } from '../utils/style/Atoms'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import colors from '../utils/style/colors'
import { SurveyContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Loader } from '../utils/style/Atoms'
import MessageCard from '../components/MessageCard'
import axios from "axios"
import Linkify from 'react-linkify';
import Header from '../components/Header'
import dateFormat from 'dateformat';

const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const MessageCardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const MessagesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${colors.backgroundDark};
`
const Details = styled.h1`
	margin: 0.1em;
	font-size: 0.8em;
	color: #f5e4c7;
`
const UserWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
	background-color: ${colors.backgroundDark};
`
const TopMessagesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
	background-color: ${colors.backgroundDark};
`
const TopActivitiesWrapper = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
	background-color: ${colors.backgroundDark};
`
const HorizontalWrapper = styled.div`
	display: flex;
	flex-direction: line;
	margin-top: 0.8em;
	margin-bottom: 0.5em;
`
const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.5em;
	color: white;
	width: 87%;
	text-align: left;
`
const Picture = styled.img`
	height: 10em;
	width: 10em;
	border-radius: 25px;
`
const Title = styled.h1`
	margin: 0.1em;
	font-size: 1em;
`

const Location = styled.span`
	color: ${colors.secondary};
`
const InputMessage = styled.span`
	width: 100%;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

function User() {
	var { id: queryId } = useParams();
	const { save_answers, answers } = useContext(SurveyContext);
	const [notif_count, set_notif_count] = useState(0);
	const [user_data, set_user_data] = useState({});
	const [is_user, set_is_user] = useState("");
	const [link, setLink] = useState("");
	const [private_messages, set_private_messages] = useState({});
	const [inputSelectedFile, setInputSelectFile] = useState('')
	const [edit_page, set_edit_page] = useState(false);
	const [activity_user_link_data, setactivity_user_link_data] = useState({});
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!answers[33]) {
			if (!localStorage.getItem('token')) { navigate('/login'); answers[34] = "/user/" + queryId; return; }
			answers[32] = JSON.parse(localStorage.getItem('user'));
			answers[33] = localStorage.getItem('token');
			console.log("localStorage user : ", answers[32]);
			console.log("localStorage token : ", answers[33]);
		}
		if (answers[32]) {
			if (queryId === "my_profile") {
				queryId = answers[32]._id;
			}
			set_is_user(queryId === answers[32]._id);
		}
		get_user_data();
		if (!is_user) {
			get_user_link_data();
		}
		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])

	function get_user_data() {
		console.log("get one User " + queryId);
		axios.get(`${Constants.BACKEND_URL}user/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get User : ", response.data);
				set_user_data(response.data);
				get_activity_user_link_data();
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}

	function get_user_link_data() {
		axios.get(`${Constants.BACKEND_URL}user_link/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get userLinkData : ", response.data);
				var link = response.data;
				if (link === null) {
					link = "null"; // needed cause null.status give "TypeError: Cannot read properties of null (reading 'status')"
				}
				if (link.status === "request") {
					link.status = link.userId === answers[32]._id ? "request_to_user" : "request_from_user";
				}
				setLink(link);
				if (link.status === "valid") {
					get_messages();
				}
			}).catch(error => console.error(error));
	}

	function create_user_link_data() {
		console.log("create_user_link_data : ", queryId);
		axios.post(`${Constants.BACKEND_URL}user_link`, {
			"linked_userId": queryId
		},
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("create_user_link_data : ", response.data);
				get_user_link_data();
			}).catch(error => console.error(error));
	}

	function update_user_link_data(status) {
		console.log("update_user_link_data : ", status);
		axios.put(`${Constants.BACKEND_URL}user_link`, {
			"_id": link._id,
			"userId": link.userId,
			"linked_userId": link.linked_userId,
			"status": status
		}, { headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("update_user_link_data response : ", response.data);
				get_user_link_data();
			}).catch(error => console.error(error));
	}

	function delete_user_link_data() {
		console.log("delete_user_link_data : ", link);
		if (window.confirm("Supprimer le contact ?")) {
			axios.delete(`${Constants.BACKEND_URL}user_link/${link._id}`,
				{ headers: { Authorization: `Bearer ${answers[33]}` } })
				.then(response => {
					console.log("delete_user_link_data response : ", response.data);
					get_user_link_data();
				}).catch(error => console.error(error));
		}
	}

	function get_activity_user_link_data() {
		console.log("get_activity_user_link_data : ", queryId);
		axios.get(`${Constants.BACKEND_URL}activity_user_link/user/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get activity_user_link_data : ", response.data);
				setactivity_user_link_data(response.data);
			}).catch(error => console.error(error));
	}

	function save() {
		console.log("save user : ", queryId);
		console.log("inputSelectedFile : ", inputSelectedFile);
		setLoading(true);
		const formData = new FormData();
		formData.append('image', inputSelectedFile[0]);
		formData.append('user', JSON.stringify({
			"city": $('#city').val(),
			"title": $('#title').val(),
			"name": $('#name').val(),
			"email": $('#email').val(),
			"birthday": $('#birthday').val(),
			"gender": $('#gender').val(),
			"surname": $('#surname').val(),
			"phone": $('#phone').val(),
			"description": $('#description').val(),
			"access": $('#access').val()
		}));
		axios.put(`${Constants.BACKEND_URL}user`, formData,
			{ headers: { Authorization: `Bearer ${answers[33]}`, 'Content-Type': 'multipart/form-data' } })
			.then(response => {
				console.log("save User response : ", response.data);
				answers[32] = response.data.user
				answers[33] = response.data.token
				localStorage.setItem('user', JSON.stringify(response.data.user));
				localStorage.setItem('token', response.data.token);
				setLoading(false);
				window.location.reload();
			}).catch(error => console.error(error));
	}

	function delete_account() {
		console.log("delete_account ");
		if (window.confirm("Supprimer le compte ? :'(\nSVP dites moi ce qui ne vous plait pas :\n   marc.labadens@gmail.com\n   06 79 59 63 73")) {
			if (window.confirm("Êtes-vous sûr ? Cette action est irréversible !\nSVP dites moi ce qui ne vous plait pas :\n   marc.labadens@gmail.com\n   06 79 59 63 73")) {
				axios.delete(`${Constants.BACKEND_URL}user`, { headers: { Authorization: `Bearer ${answers[33]}` } })
					.then(response => {
						console.log("delete_account User response : ", response.data);
						localStorage.removeItem('user');
						localStorage.removeItem('token');
						window.location.reload();
					}).catch(error => console.error(error));
			}
		}
	}

	function set_godfather() {
		console.log("set_godfather");
		if (window.confirm("Déclarer comme parrain ? Cela lui donnera des points en récompense. Cette action ne peut être faite qu'une seule fois.")) {
			axios.post(`${Constants.BACKEND_URL}user/set_godfather`, {
				"_id": queryId,
				"title": user_data.title
			}, { headers: { Authorization: `Bearer ${answers[33]}` } })
				.then(response => {
					console.log("set_godfather response : ", response.data);
					var user_local_data = answers[32];
					user_local_data.godfather_title = "user_data.title";
					localStorage.setItem('user', JSON.stringify(user_local_data));
					window.location.reload();
				}).catch(error => console.error(error));
		}
	}

	function send_message() {
		console.log("send_message to user : ", queryId);
		axios.post(`${Constants.BACKEND_URL}private_message`, {
			"target_userId": queryId,
			"content": $('#message').val()
		}, { headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("save message response : ", response.data);
				get_messages();
			}).catch(error => console.error(error));
		$('#message').val("");
	}

	function get_messages() {
		console.log("get_messages with user : ", queryId);
		axios.get(`${Constants.BACKEND_URL}private_message/${queryId}`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("get private_message response : ", response.data);
				set_private_messages(response.data);
			}).catch(error => console.error(error));
	}

	function getAge(dateString) {
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	function toDateString(date) {
		var pad = function (num) {
			return (num < 10 ? '0' : '') + num;
		};
		try {
			var date_object = new Date(date);
			return date_object.getFullYear() +
				'-' + pad(date_object.getMonth() + 1) +
				'-' + pad(date_object.getDate());
		} catch {
			return "";
		}
	}

	return (
		<div>
			<Header notif_count={notif_count} />

			<TopContainer>

				<UserDetails>
					<HorizontalWrapper>
						{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
						{is_user && edit_page && !isLoading && (
							<HorizontalWrapper>
								<Location><StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink></Location>
								<Location>&nbsp;</Location><Location>&nbsp;</Location>
								<Location><StyledLink $isFullLink onClick={() => set_edit_page(false)}>Annuler</StyledLink></Location>
							</HorizontalWrapper>
						)}
						{is_user && !edit_page && (
							<StyledLink $isFullLink onClick={() => set_edit_page(true)}>
								Modifier
							</StyledLink>
						)}

						{link === "null" && !is_user && (
							<StyledLink $isFullLink onClick={() => create_user_link_data()}>
								Ajouter aux contacts
							</StyledLink>
						)}
						{link.status === "request_from_user" && (
							<StyledLink $isFullLink onClick={() => update_user_link_data("valid")}>
								Accepter le contact
							</StyledLink>
						)}
						{link.status === "request_to_user" && (
							<StyledLink>
								Demande de contact envoyée
							</StyledLink>
						)}
						{link.status === "request_to_user" && (
							<StyledLink $isFullLink onClick={() => delete_user_link_data()}>
								Annuler la demande de contact
							</StyledLink>
						)}
						{link.status === "valid" && (
							<StyledLink $isFullLink onClick={() => delete_user_link_data()}>
								Retirer des contacts
							</StyledLink>
						)}
						{!is_user && answers[32] && !answers[32].godfather_title && (
							<StyledLink $isFullLink onClick={() => set_godfather()}>
								Déclarer comme parrain
							</StyledLink>
						)}
					</HorizontalWrapper>
				</UserDetails>
				<UserWrapper>
					<a href={`${Constants.BACKEND_URL}images/${user_data.image}`}>
						<Picture src={`${Constants.BACKEND_URL}images/${user_data.image}`} alt={user_data._id} height={150} width={150} />
					</a>
					<UserDetails>
						{edit_page && <TitleWrapper><Location><input type="file" onChange={(e) => setInputSelectFile(e.target.files)} /></Location><Title>Modifier l'image</Title></TitleWrapper>}

						{edit_page ? (<TitleWrapper>
							<Location><input id="title" defaultValue={user_data.title} /></Location><Title>Nom affiché</Title></TitleWrapper>
						) : (<Title>{user_data.title}</Title>)}

						{edit_page ? (<TitleWrapper>
							<Location><input id="name" defaultValue={user_data.name} /></Location><Title>Prénom</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><input id="surname" defaultValue={user_data.surname} /></Location><Title>Nom</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><span>{user_data.email}</span></Location><Title>Mail</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><input id="phone" placeholder="06XXXXXXXX" maxlength="10" defaultValue={user_data.phone} /></Location><Title>Téléphone</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><input id="city" defaultValue={user_data.city} /></Location><Title>Ville</Title></TitleWrapper>
						) : (<span>{user_data.city}</span>)}


						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="gender" >
									<option value="null" selected={user_data.gender === "null"} ></option>
									<option value="m" selected={user_data.gender === "m"} >Homme</option>
									<option value="f" selected={user_data.gender === "f"} >Femme</option>
								</select>
							</Location>
							<Title>Genre</Title>
						</TitleWrapper>
						) : ("")}

						{edit_page && user_data.birthday ? (<TitleWrapper>
							<Location><input id="birthday" type="date" defaultValue={toDateString(user_data.birthday)} /></Location><Title>Date de naissance</Title></TitleWrapper>
						) : ("")}
						{edit_page && !user_data.birthday ? (<TitleWrapper>
							<Location><input id="birthday" type="date" /></Location><Title>Date de naissance</Title></TitleWrapper>
						) : ("")}
						{user_data.birthday ? (
							<Details>{getAge(user_data.birthday)}&nbsp;Ans</Details>
						) : ("")}


						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="access">
									<option value="public" selected={user_data.access === "public"} >Tous les utilisateurs connectés</option>
									<option value="friends_only" selected={user_data.access === "friends_only"} >Amis seulement</option>
								</select>
							</Location><Details>Visibilité</Details></TitleWrapper>
						) : (<TitleWrapper><Details>Visibilité&nbsp;:&nbsp;&nbsp;</Details>
							{"public" === user_data.access ? ("Tous les utilisateurs connectés") : ("")}
							{"friends_only" === user_data.access ? ("Amis seulement") : ("")}
							{"private" === user_data.access ? ("Seulement moi") : ("")}
						</TitleWrapper>)}

						{user_data.last_connection ? (
							<div>
								<TitleWrapper><Details>Derniere connexion &nbsp;:&nbsp;&nbsp;</Details>{dateFormat(new Date(user_data.last_connection), "d mmm yyyy")}</TitleWrapper>
								<TitleWrapper><Details>Derniere modification &nbsp;:&nbsp;&nbsp;</Details>{dateFormat(new Date(user_data.modified), "d mmm yyyy")}</TitleWrapper>
								<TitleWrapper><Details>Compte crée &nbsp;:&nbsp;&nbsp;</Details>{dateFormat(new Date(user_data.created), "d mmm yyyy")}</TitleWrapper>
								<TitleWrapper><Details>Nombre de vue &nbsp;:&nbsp;&nbsp;</Details>{user_data.view_count}</TitleWrapper>
								<TitleWrapper><Details>Score du mois en cours&nbsp;:&nbsp;&nbsp;</Details>{user_data.month_score}</TitleWrapper>
								<TitleWrapper><Details>Score du dernier mois&nbsp;:&nbsp;&nbsp;</Details>{user_data.last_month_score}</TitleWrapper>
								<TitleWrapper><Details>Score &nbsp;:&nbsp;&nbsp;</Details>{user_data.score}</TitleWrapper>
								{user_data.godfather_title && (
									<TitleWrapper><Details>Parain &nbsp;:&nbsp;&nbsp;</Details>{user_data.godfather_title}</TitleWrapper>
								)}
							</div>
						) : ("")}
					</UserDetails>
				</UserWrapper>
				<UserWrapper>
					<UserDetails>

						<TitleWrapper>{edit_page ? (
							<textarea id="description" placeholder="Ecrit ce que tu veux !"
								rows={12} style={{ width: '98%' }} maxLength={10000} wrap="soft" defaultValue={user_data.description} />
						) : (
							<Title><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{user_data.description}</div></Linkify></Title>
						)}
						</TitleWrapper>

					</UserDetails>
				</UserWrapper>
				{link.status === "valid" && (
					<TopMessagesWrapper>
						<MessagesWrapper>
							<MessageCardsContainer>
								{Array.isArray(private_messages) && (
									private_messages?.map((msg) => (
										<MessageCard key={`msg-${msg._id}`}
											content={msg.content}
											created={msg.created}
											user_title_hidden={(msg.userId === queryId) ? (user_data.title) : (answers[32].title)}
											user_image={(msg.userId === queryId) ? (`${Constants.BACKEND_URL}images/${user_data.image}`) : (`${Constants.BACKEND_URL}images/${answers[32].image}`)}
										/>
									)))}
							</MessageCardsContainer>
						</MessagesWrapper>
						<MessagesWrapper>
							<StyledLink $isFullLink onClick={() => send_message()}>&gt;</StyledLink>
							<InputMessage>
								<textarea id="message" rows={3} style={{ width: '98%' }} maxLength={1000} wrap="soft"
									placeholder="Ajouter un message privé" />
							</InputMessage>
						</MessagesWrapper>
					</TopMessagesWrapper>
				)}
				<UserWrapper>
					&nbsp;
				</UserWrapper>
				<TopActivitiesWrapper>
					{Array.isArray(activity_user_link_data) &&
						activity_user_link_data?.map((link) => (
							<Link key={`activity-${link.activityId}`} to={`/activity/${link.activityId}`}>
								<ActivitySmallCard
									title={link.activity_title}
									date={link.activity_date}
									picture={`${Constants.BACKEND_URL}images/${link.activity_image}`}
								/>
							</Link>
						))}
				</TopActivitiesWrapper>

				<div>&nbsp;</div>
				{is_user && (
					<UserWrapper>
						<StyledLink $isFullLink onClick={() => delete_account()}>Supprimer mon compte</StyledLink>
					</UserWrapper>
				)}
			</TopContainer>
		</div>
	)
}

export default User
