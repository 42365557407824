import styled from 'styled-components'
import * as Constants from '../utils/Constants';
import { Link } from 'react-router-dom'
import ActivityCard from '../components/ActivityCard'
import colors from '../utils/style/colors'
import { Loader } from '../utils/style/Atoms'
import { useState, useEffect, useContext } from 'react';
import { SurveyContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import Header from '../components/Header'
import axios from "axios"

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-items: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const PageSubtitle = styled.h2`
	font-size: 20px;
	color: ${colors.secondary};
	font-weight: 300;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`

function Activities() {
	const [activityArray, setStuffArray] = useState(null);
	const [notif_count, set_notif_count] = useState(0);
	const [isLoading, setLoading] = useState(null);
	const [error, setError] = useState(null);
	const [user_title, set_user_title] = useState(null);
	const { answers } = useContext(SurveyContext)
	console.log("isLoading = ", isLoading)
	const navigate = useNavigate();

	useEffect(() => {
		if (!answers[33]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			answers[32] = JSON.parse(localStorage.getItem('user'));
			answers[33] = localStorage.getItem('token');
			console.log("localStorage user : ", answers[32]);
			console.log("localStorage token : ", answers[33]);
		}
		set_user_title(answers[32].title);
		setLoading('Chargement en cours...');
		const source = axios.CancelToken.source();
		console.log("gets all axios");
		axios.get(`${Constants.BACKEND_URL}activity`,
			{ cancelToken: source.token, headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				setLoading(false);
				console.log("responseezf : ", response);
				setStuffArray(response.data);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});
		
		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});
		
		return () => {
			source.cancel();
		}
	}, [])

	return (
		<div>
			<Header notif_count={notif_count}/>
			{isLoading ? (
				<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>
			) : (
				<CardsContainer>
					{activityArray?.map((activity) => (
						<Link key={`activity-${activity._id}`} to={`/activity/${activity._id}`}>
							<ActivityCard
								label={activity.current_user_number + "/" + activity.max_user_number + " " + activity.title}
								date={activity.date}
								picture={`${Constants.BACKEND_URL}images/${activity.image}`}
							/>
						</Link>
					))}
				</CardsContainer>
			)}
			<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>
			<PageSubtitle>{error && <p>{error}</p>}</PageSubtitle>
		</div>
	)
}

export default Activities
