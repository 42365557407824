import { Component } from 'react'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import dateFormat from 'dateformat';

const CardLabel = styled.span`
	color: #ffffff;
	font-size: 1.3em;
	font-weight: normal;
`

const CardImage = styled.img`
	height: 9em;
	width: 9em;
	align-self: center;
	border-radius: 15%;

`

const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0.6em;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	&:hover {cursor: pointer;}

`

const CardTitle = styled.div`
	color: #ffffff;
	font-size: 1.3em;
	font-weight: normal;
	align-self: center;
	height: 1.2em;
	display: flex;
	align-items: center;
`

class ActivityCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { picture, label, date } = this.props

		return (
			<CardWrapper onClick={this.setFavorite}>
				<CardLabel>{label}</CardLabel>
				<CardImage src={picture} alt="freelance" />
				<CardTitle>{dateFormat(new Date(date), "d mmm HH:MM")}</CardTitle>
			</CardWrapper>
		)
	}
}

export default ActivityCard
