import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import ChatMessage from './pages/ChatMessage'
import Notifications from './pages/Notifications'
import Podium from './pages/Podium'
import CreateActivity from './pages/CreateActivity'
import Activities from './pages/Activities'
import Activity from './pages/Activity'
import User from './pages/User'
import Users from './pages/Users'
import Error from './components/Error'
import GlobalStyle from './utils/style/GlobalStyle'
import { ThemeProvider, SurveyProvider } from './utils/context'
import BurgerMenu from './components/BurgerMenu'

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<ThemeProvider>
				<SurveyProvider>
					<div id="outer-container">
						<BurgerMenu pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
						<main id="page-wrap">
							<GlobalStyle />
							<Routes>
								<Route path="/" element={<Activities />} />
								<Route path="/users" element={<Users />} />
								<Route path="/login" element={<Login />} />
								<Route path="/chat" element={<ChatMessage />} />
								<Route path="/notifications" element={<Notifications />} />
								<Route path="/podium" element={<Podium />} />
								<Route path="/createActivity" element={<CreateActivity />} />
								<Route path="/activity/:id" element={<Activity />} />
								<Route path="/user/:id" element={<User />} />
								<Route path="*" element={<Error />} />
							</Routes>
						</main>
					</div>
				</SurveyProvider>
			</ThemeProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
)
