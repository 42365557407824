import $ from 'jquery';
import * as Constants from '../utils/Constants';
import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { StyledLink } from '../utils/style/Atoms'
import axios from "axios"
import { UserContext, SurveyContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import Header from '../components/Header'
import './main.css';

const HomeWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const HomerContainer = styled.div`
	background-color: ${colors.backgroundDark};
	display: flex;
	width: 100%;
	flex-direction: row;
`

const LeftCol = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	${StyledLink} {
		max-width: 250px;
	}
`

const StyledTitle = styled.h2`
	padding-bottom: 30px;
	max-width: 280px;
	line-height: 50px;
	color: #ff6767;
`

function Login() {
	const [email, setMail] = useState('');
	const [password, setPassword] = useState('');
	const [error_msg, set_error_msg] = useState('');
	const { save_answers, answers } = useContext(SurveyContext);
	localStorage.clear();
	answers[32] = undefined;
	answers[33] = undefined;
	console.log("answers : ", answers);
	console.log("localStorage token : ", localStorage.getItem('token'));
	const navigate = useNavigate();

	useEffect(() => {
		console.log("Wake up server request");
		
		axios.get(`${Constants.BACKEND_URL}`).then(response => {
			console.log("Server is up :-) : ", response.data);
		}).catch(error => {
			console.log(error);
			var errMsg = JSON.stringify(error.response.data.error);
			set_error_msg(errMsg)
		});
	}, [])


	function register() {
		console.log("Register as " + email);
		if (email.length < 6) {
			set_error_msg("Le mail doit contenir au moins 6 caractères")
			return;
		}
		if (!email.includes("@")) {
			set_error_msg("Le mail doit contenir le caractère @")
			return;
		}
		if (password.length < 6) {
			set_error_msg("Le mot de passe doit contenir au moins 6 caractères")
			return;
		}
		const email_split = email.split('@');
		var title = email_split[0]
		console.log("User default title : ", title);

		axios.post(`${Constants.BACKEND_URL}user`, {
			"email": email, "password": password, "name": title, "title": title, "city": "Toulouse"
		}).then(response => {
			console.log("User created : ", response.data);
			login();
		}).catch(error => {
			console.log(error);
			var errMsg = JSON.stringify(error.response.data.error);
			if (errMsg.includes("unique")) { errMsg = "Cette adresse mail existe déjà !" }
			set_error_msg(errMsg)
		});
	}

	function login() {
		console.log("Login as " + email);
		axios.post(`${Constants.BACKEND_URL}user/login`, {
			"email": email, "password": password
		}).then(response => {
			console.log("Login done : ", response.data);
			//setUserContext({ "loginToken": response.data.token });
			localStorage.setItem('user', JSON.stringify(response.data.user));
			localStorage.setItem('token', response.data.token);
			answers[32] = JSON.parse(localStorage.getItem('user'));
			answers[33] = localStorage.getItem('token');
			if (answers[34]) { navigate(answers[34]); } else { navigate('/'); }
		}).catch(error => set_error_msg(JSON.stringify(error.response.data.error)));
	}

	function login_with_google() {
		console.log("login_with_google token " + $('#google_token').val());
		axios.post(`${Constants.BACKEND_URL}user/login_with_google`, JSON.parse($('#google_token').val()))
			.then(response => {
				console.log("Login done : ", response.data);
				//setUserContext({ "loginToken": response.data.token });
				localStorage.setItem('user', JSON.stringify(response.data.user));
				localStorage.setItem('token', response.data.token);
				answers[32] = JSON.parse(localStorage.getItem('user'));
				answers[33] = localStorage.getItem('token');
				if (answers[34]) { navigate(answers[34]); } else { navigate('/'); }
			}).catch(error => set_error_msg(JSON.stringify(error.response.data.error)));
	}

	$("#email").keyup(function (event) {
		if (event.keyCode === 13) {
			login();
		}
	});

	$("#password").keyup(function (event) {
		if (event.keyCode === 13) {
			login();
		}
	});

	return (
		<div>
			<Header notif_count={0} />
			<HomeWrapper>
				<HomerContainer>
					<LeftCol>

						<div class="montitre">Site de rencontre et d’organisation de sorties sur Toulouse et ses environs</div>
						<div class="gros">Rencontre de nouvelles personnes près de chez toi 😄</div>
						<div class="gros">Organise et participe à de belles sorties</div>
						<div class="normal">Choisi qui peux t’envoyer des messages</div>
						<div class="trespetit" style={{display: 'none'}}>Compte basic : gratuit à vie</div>
						<div class="trespetit" style={{display: 'none'}}>Compte premium : 10€/mois</div>
						<div class="petit_fin" style={{display: 'none'}}>Compte premium <mark>&nbsp;offert à vie&nbsp;</mark> pour les 1000 premiers utilisateurs du site</div>
						<div class="normal"><mark>&nbsp;Entièrement gratuit&nbsp;</mark></div>
						<div class="normal"><mark>&nbsp;Inscris-toi aujourd'hui et bénéfie d'un compte VIP 😎&nbsp;</mark></div>
						<div class="gros">Site 100% made in Toulouse 🌞</div>

						<StyledLink $isFullLink onClick={() => login()}>
							Se connecter
						</StyledLink>
						Mail <input id="email" placeholder="example@gmail.com" onChange={(e) => setMail(e.target.value)} />
						<br></br>
						Mot de passe <input id="password" placeholder="XXXXXX" type="password" onChange={(e) => setPassword(e.target.value)} />
						<br></br>

						<StyledLink $isFullLink onClick={() => register()}>
							Crée un compte en un instant
						</StyledLink>
						<StyledTitle>
							{error_msg}
							<div id="g_id_onload"
								data-client_id="520493252309-25epr1jbibkvo2qsfq3dllqc84umu8jc.apps.googleusercontent.com"
								data-context="signin"
								data-ux_mode="popup"
								data-callback="login_with_google_callback"
								data-auto_select="true"
								data-itp_support="true">
							</div>

							<div class="g_id_signin"
								data-type="standard"
								data-shape="pill"
								data-theme="outline"
								data-text="signin_with"
								data-size="large"
								data-locale="fr"
								data-logo_alignment="left">
							</div>
							<input id="google_token" onClick={() => login_with_google()} type="hidden" />
						</StyledTitle>
					</LeftCol>
				</HomerContainer>
			</HomeWrapper>
		</div>
	)
}

export default Login
