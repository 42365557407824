import React, { useState, createContext } from 'react'

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState('dark')
	const toggleTheme = () => {
		setTheme(theme === 'light' ? 'dark' : 'light')
	}

	return (
		<ThemeContext.Provider value={{ theme, toggleTheme }}>
			{children}
		</ThemeContext.Provider>
	)
}

export const SurveyContext = createContext()

export const SurveyProvider = ({ children }) => {
	const [answers, setAnswers] = useState({})
	const saveAnswers = (newAnswers) => {
		setAnswers({ ...answers, ...newAnswers })
	}

	return (
		<SurveyContext.Provider value={{ answers, saveAnswers }}>
			{children}
		</SurveyContext.Provider>
	)
}

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
	const [userContext, setUserContext] = useState({});

	return (
		<UserContext.Provider value={{ userContext, setUserContext }}>
			{children}
		</UserContext.Provider>
	)
}
