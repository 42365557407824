import $ from 'jquery';
import * as Constants from '../utils/Constants';
import { useState, useEffect, useContext } from 'react';
import MessageCard from '../components/MessageCard'
import { StyledLink } from '../utils/style/Atoms'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { SurveyContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Loader } from '../utils/style/Atoms'
import Header from '../components/Header'
import axios from "axios"

const CardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const ActivityContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`
const ActivityWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
	background-color: ${colors.backgroundDark};
`
const InputTextWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: line;
	justify-content: center;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
	background-color: ${colors.backgroundDark};
`
const Location = styled.span`
	width: 100%;
	color: ${colors.secondary};
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`

function ChatMessage() {
	const { save_answers, answers } = useContext(SurveyContext);
	const [notif_count, set_notif_count] = useState(0);
	const [chat_messages, set_chat_messages] = useState({});
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!answers[33]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			answers[32] = JSON.parse(localStorage.getItem('user'));
			answers[33] = localStorage.getItem('token');
			console.log("localStorage user : ", answers[32]);
			console.log("localStorage token : ", answers[33]);
		}
		setLoading('Chargement en cours...');
		get_chat_message();
		// document.querySelector("input").addEventListener("keyup", input_key_up);

		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])

	function get_chat_message() {
		console.log("get_chat_message");
		axios.get(`${Constants.BACKEND_URL}chat_message`,
			{ headers: { Authorization: `Bearer ${answers[33]}` } })
			.then(response => {
				setLoading(false);
				console.log("get_chat_message : ", response.data);
				set_chat_messages(response.data);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}

	/*		// does not work : send x10 the same message...
	function input_key_up(event) {
		if (event.code === "Enter") {
			console.log("send message : ", $('#message').val());
			send_message();
		}
	}*/

	function send_message() {
		if ($('#message').val()) {
			console.log("send message : ", $('#message').val());
			axios.post(`${Constants.BACKEND_URL}chat_message`, {
				"content": $('#message').val()
			}, { headers: { Authorization: `Bearer ${answers[33]}` } })
				.then(response => {
					console.log("send message response : ", response.data);
					get_chat_message();
				}).catch(error => console.error(error));
			$('#message').val("");
		}
	}

	return (
		<div>
			<Header notif_count={notif_count} />
			<ActivityContainer>
				{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(chat_messages) && (
							chat_messages?.map((msg) => (
								<MessageCard
									key={msg._id}
									content={msg.content}
									created={msg.created}
									user_id={msg.userId}
									user_title={msg.user_title}
									user_image={`${Constants.BACKEND_URL}images/${msg.user_image}`}
								/>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				<InputTextWrapper>
					<StyledLink $isFullLink onClick={() => send_message()}>&gt;</StyledLink>
					<Location>
						<textarea id="message" rows={3} style={{ width: '99%' }} maxLength={1000} wrap="soft"
							placeholder="Ajouter un message visible pendant 48h par tous les utilisateurs connectés" />
					</Location>
				</InputTextWrapper>
				<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>
			</ActivityContainer>
		</div>
	)
}

export default ChatMessage
